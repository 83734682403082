import Image from '@/components/atoms/Image';
import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import ClampedText from '@/components/molecules/ClampedText';
import { EVComparisonChainModel } from '@/components/organisms/EVComparisonChainModel/EVComparisonChainModel';
import {
  COMPARE,
  FAVORITE,
  POPULAR_EVS_COMPARISON,
  SIDE_BY_SIDE,
  START_COMPARISON,
} from '@/lib/constants';
import { EVDetailProps } from '@/types/compare-ev';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { VerseIcon } from '../../atoms/icons';

interface EVComparisonLandingTemplateProps {
  makes: string[];
  selectedModelsCompare: EVDetailProps[][];
}

export const generateEVQuery = ({
  year,
  make,
  model,
  trim,
}: Omit<EVDetailProps, 'imageURL' | 'MSRP'>) =>
  [year, make, model, trim]
    .map((item) =>
      item
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-.]/g, '')
    )
    .join('-');

export const EVComparisonLandingTemplate: React.FC<
  EVComparisonLandingTemplateProps
> = ({ makes, selectedModelsCompare }) => {
  const [modalStep, setModalStep] = useState(0);
  const router = useRouter();

  function goToNextModal(step: number) {
    setModalStep(step + 1);
  }

  const handlePopularEVClick = useCallback(
    async (pairModels: EVDetailProps[]) => {
      const { imageURL: imageURL1, ...rest1 } = pairModels[0];
      const { imageURL: imageURL2, ...rest2 } = pairModels[1];
      sessionStorage.setItem('firstEVImageUrl', imageURL1);
      sessionStorage.setItem('secondEVImageUrl', imageURL2);
      const firstEVQuery = generateEVQuery(rest1);
      const secondEVQuery = generateEVQuery(rest2);
      router.push(`/compare/details/${firstEVQuery}&${secondEVQuery}`);
    },
    [router]
  );

  const onSelectTrims = useCallback(
    async (body: {
      selectedDefaultDetails: EVDetailProps;
      firstEV: EVDetailProps;
      secondEV: EVDetailProps;
    }) => {
      const { selectedDefaultDetails, firstEV, secondEV } = body;

      try {
        const response = await fetch(
          `/api/compare/getVehicleStylesData/?year=${selectedDefaultDetails.year}&make=${
            selectedDefaultDetails.make
          }&model=${selectedDefaultDetails.model}&trim=${encodeURIComponent(
            selectedDefaultDetails.trim
          )}`
        );
        if (!response.ok) {
          return false;
        }
        handlePopularEVClick([firstEV, secondEV]);
        return response.ok;
      } catch (error) {
        return false;
      }
    },
    [handlePopularEVClick]
  );

  return (
    <>
      <section className="mb-[32px] flex h-auto w-full flex-col items-center justify-between bg-neutral-100">
        <Heading
          className="w-full px-[50px] pb-xl pt-[48px] text-center text-[clamp(1.5rem,2.5vw,2.2rem)]"
          level={HeadingLevels.H4}
        >
          <span>{COMPARE}</span>
          <span className="inline text-brandElectric">{FAVORITE}</span>
          <br />
          <span>{SIDE_BY_SIDE}</span>
        </Heading>
        <div className="mb-[32px]">
          <Button
            variant={ButtonVariants.Tertiary}
            onClick={() => setModalStep(1)}
            aria-label="start comparison"
          >
            {START_COMPARISON}
          </Button>
        </div>
      </section>
      <section className="mb-[20px] flex w-full justify-around l:mb-[40px]">
        <section className="h-full w-full bg-neutral-100 m:w-[50%]">
          <Heading
            className="mb-[20px] w-full px-[20px] pt-[30px] text-[clamp(1rem,1.3vw,1.5rem)] l:mb-[40px] l:px-[40px]"
            level={HeadingLevels.H4}
          >
            <span>{POPULAR_EVS_COMPARISON}</span>
          </Heading>
          <section>
            <section className="flex flex-col px-[20px] l:px-[40px]">
              {selectedModelsCompare.map((pairModels, index: number) => (
                <div
                  className="mb-[16px] flex w-full items-center justify-center space-x-8 rounded-[4px] bg-white p-4 hover:cursor-pointer hover:shadow-md hover:ring-2 hover:ring-brandElectric hover:ring-opacity-50 m:mb-[24px]"
                  key={index}
                  onClick={() => handlePopularEVClick(pairModels)}
                >
                  <div className="align-center flex flex-col justify-center text-center">
                    <div className="flex min-h-[72px] items-center">
                      <Image
                        src={pairModels[0].imageURL}
                        alt="compare evs"
                        className="!relative mx-auto -scale-x-100 transform object-contain"
                        fill
                      />
                    </div>
                    <ClampedText className="whitespace-nowrap text-[12px] text-neutral-600 l:text-[16px]">
                      {pairModels[0].year} {pairModels[0].make}
                    </ClampedText>
                    <p className="text-[12px] l:text-[16px]">
                      {pairModels[0].model}
                    </p>
                    <ClampedText className="text-[12px] text-neutral-600 l:text-[14px]">
                      {pairModels[0].trim}
                    </ClampedText>
                  </div>
                  <div className="h-[36px] w-[36px]">
                    <VerseIcon />
                  </div>
                  <div className="align-center flex flex-col justify-center text-center">
                    <div className="flex min-h-[72px] items-center">
                      <Image
                        src={pairModels[1].imageURL}
                        alt="compare evs"
                        className="!relative mx-auto object-contain"
                        fill
                      />
                    </div>
                    <ClampedText className="whitespace-nowrap text-[12px] text-neutral-600 l:text-[14px]">
                      {pairModels[1].year} {pairModels[1].make}
                    </ClampedText>
                    <p className="text-[12px] l:text-[14px]">
                      {pairModels[1].model}
                    </p>
                    <ClampedText className="text-[12px] text-neutral-600 l:text-[14px]">
                      {pairModels[1].trim}
                    </ClampedText>
                  </div>
                </div>
              ))}
            </section>
          </section>
        </section>
      </section>
      <EVComparisonChainModel
        modalStep={modalStep}
        goToNextModal={goToNextModal}
        makes={makes}
        onSubmit={onSelectTrims}
      />
    </>
  );
};
